import React from "react"
import { Helmet } from "react-helmet"
import "../../static/assets/scss/pages/page.scss"
import LayoutDark from "./shared/layout/layout-dark"
import SignUpFormComponent from "./shared/components/sign-up-form.component"

const routes = require("../types/routes")

export default class Procurement extends React.Component {
  constructor(props) {
    super(props)
    this.routes = routes
  }

  render() {
    return (
      <LayoutDark>
        <Helmet>
          <title>Contingent: Operational Resilience</title>
          <link rel="canonical" href={`${process.env.BASE_URL}`} />
          <meta name="title" content="Contingent: Operational Resilience" />
          <meta
            name="description"
            content="Becoming and remaining resilient is easier than you think
                      with an all-in-one operational resilience platform that
                      provides your organisation with the tools to maintain
                      compliance."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={`${process.env.BASE_URL}/`} />
          <meta
            property="og:title"
            content="Contingent: Operational Resilience"
          />
          <meta
            property="og:description"
            content="Becoming and remaining resilient is easier than you think
                      with an all-in-one operational resilience platform that
                      provides your organisation with the tools to maintain
                      compliance."
          />
          <meta
            property="og:image"
            content={`${process.env.BASE_URL}/assets/images/web/social-image.png`}
          />

          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content={`${process.env.BASE_URL}/`} />
          <meta
            property="twitter:title"
            content="Contingent: Operational Resilience"
          />
          <meta
            property="twitter:description"
            content="Becoming and remaining resilient is easier than you think
                      with an all-in-one operational resilience platform that
                      provides your organisation with the tools to maintain
                      compliance."
          />
          <meta
            property="twitter:image"
            content={`${process.env.BASE_URL}/assets/images/web/social-image.png`}
          />
        </Helmet>
        <div className={"section-container"}>
          <div className={"section-info basic detail"}>
            <div className={"container contact-us"}>
              <div className={"row"}>
                <div className={"column centered texture-4"}>
                  <div className={"content"}>
                    <h1 className={"title"}>Operational Resilience</h1>
                    <p className={"description"}>
                      Becoming and remaining resilient is easier than you think
                      with an all-in-one operational resilience platform that
                      provides your organisation with the tools to maintain
                      compliance.
                    </p>
                    <div className={"images"}>
                      <object
                        data={"/assets/images/logos/bci-trans.svg"}
                        className={"card"}
                      ></object>
                      <object
                        data={"/assets/images/logos/pra-transparent.svg"}
                        className={"card"}
                      ></object>
                      <object
                        data={"/assets/images/logos/eba.svg"}
                        className={"card"}
                      ></object>
                    </div>
                    <SignUpFormComponent mode={"light"}></SignUpFormComponent>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={"section-info"}>
            <div className={"container"}>
              <div className={"row"}>
                <div className={"column reversed"}>
                  <div className={"content"}>
                    <h2 className={"title"}>
                      Identify and map important business services
                    </h2>
                    <p className={"description"}>
                      Identifying those services which are critical in
                      delivering good customer outcomes is difficult.
                      Identifying the separate services, and not a collection of
                      services, is even more difficult. We help you identify the
                      critical services by putting the users of the service at
                      the centre. This means you understand what the possible
                      impacts of disruption are to those users and your firm.
                    </p>
                    <SignUpFormComponent mode={"light"}></SignUpFormComponent>
                  </div>
                  <object
                    data={
                      "/assets/images/graphics/operational-resilience-1.svg"
                    }
                    className={"card"}
                  ></object>
                </div>
              </div>
            </div>
          </div>
          <div className={"section-info"}>
            <div className={"container"}>
              <div className={"row"}>
                <div className={"column"}>
                  <object
                    data={
                      "/assets/images/graphics/operational-resilience-2.svg"
                    }
                    className={"card"}
                  ></object>
                  <div className={"content reversed"}>
                    <h2 className={"title"}>
                      Ongoing monitoring and management of outsourced
                      third-party providers
                    </h2>
                    <p className={"description"}>
                      FCA, PRA, EBA, EIOPA and others require that firms have
                      better control of outsourced third-party providers. This
                      should not be a one-off or static exercise. Firms need to
                      keep processes going, we ensure this is not time-consuming
                      and resource-intensive.
                    </p>
                    <SignUpFormComponent mode={"light"}></SignUpFormComponent>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={"section-info"}>
            <div className={"container"}>
              <div className={"row"}>
                <div className={"column reversed"}>
                  <div className={"content"}>
                    <h2 className={"title"}>
                      Tolerance setting and stress testing
                    </h2>
                    <p className={"description"}>
                      Map processes and set “the maximum tolerable levels of
                      disruption to an important business service, including the
                      maximum tolerable duration of a disruption”, stress test,
                      review key risk Indicators (KRI’s) and metrics
                      automatically and all at once.
                    </p>
                    <SignUpFormComponent mode={"light"}></SignUpFormComponent>
                  </div>
                  <object
                    data={
                      "/assets/images/graphics/operational-resilience-3.svg"
                    }
                    className={"card"}
                  ></object>
                </div>
              </div>
            </div>
          </div>
          <div className={"section-info"}>
            <div className={"container"}>
              <div className={"row"}>
                <div className={"column"}>
                  <object
                    data={
                      "/assets/images/graphics/operational-resilience-4.svg"
                    }
                    className={"card"}
                  ></object>
                  <div className={"content reversed"}>
                    <h2 className={"title"}>
                      Mapping and scenario testing builds resilience and
                      compliance
                    </h2>
                    <p className={"description"}>
                      Through scenario testing and those identified through
                      other means, for example breaches, and
                      second-or-third-line monitoring, we help you get sight of,
                      evidence and address deficiencies, as a matter of
                      priority.
                    </p>
                    <SignUpFormComponent mode={"light"}></SignUpFormComponent>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"cta-section"}>
          <div className={"container"}>
            <div className={"row"}>
              <div className={"column"}>
                <div className={"content"}>
                  <p className={"tag"}>Try Contingent Now</p>
                  <h2 className={"title"}>
                    Build resilience with proactive third-party risk management
                    and monitoring today
                  </h2>
                  <SignUpFormComponent mode={"light"}></SignUpFormComponent>
                </div>
                <div className={"image"}>
                  <object data={"/assets/images/graphics/cta.svg"}></object>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutDark>
    )
  }
}
